import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import { Navbar, Nav, Container } from 'react-bootstrap';
import Home from "./home/home";
import Projects from "./projects/projects";
import Blogs from "./blogs/blogs";
import { Helmet } from 'react-helmet';
import CustomNavbar from './page/navbar';
import { ThemeProvider } from './ThemeContext';

function App() {
    return (
        <ThemeProvider>
            <div className="
                App 
                bg-gray-100
                dark:bg-neutral-800 
                dark:text-white 
                transition-colors 
                duration-300
                h-screen
            ">
                <Helmet>
                    <title></title>
                </Helmet>
                <Router>
                    <CustomNavbar/>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/projects_blogs" element={<Projects />} />
                        {/* <Route path="/blogs" element={<Blogs />} /> */}

                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
