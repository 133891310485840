import React, { useContext, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { ThemeContext } from '../ThemeContext'; // Adjust the path accordingly
import { FaSun, FaMoon } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

const CustomNavbar = () => {
    const { darkMode, toggleTheme } = useContext(ThemeContext);
    const [iconFade, setIconFade] = useState(false);

    // Handle icon fade effect
    const handleToggle = () => {
        toggleTheme();
        setIconFade(true);
        setTimeout(() => setIconFade(false), 300);
    };

    return (
        <Navbar
            expand="md"
            className={`
                transition-all 
                merriweather-regular
                duration-500 
                ${darkMode ? 'bg-neutral-900 text-white' : 'bg-white text-black'}
            `}
        >
            <Container className="tracking-wide !ml-[16px] md:!ml-7 !mr-[16px] w-full max-w-100">
                <Navbar.Brand
                    href="/"
                    className={`
                        ${darkMode ? 'text-white' : 'text-black'}
                        transition-all duration-500 font-bold
                    `}
                >
                    Keyne Oei
                </Navbar.Brand>

                {/* Navbar toggle (burger menu) */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="
                    border-0 
                    focus:text-rose-500
                    dark:focus:text-rose-400
                ">
                    {darkMode ? <FaBars color="white" /> : <FaBars color="black" />}
                </Navbar.Toggle>

                {/* Navbar links and dark mode toggle */}
                <Navbar.Collapse id="basic-navbar-nav text-base">
                    <Nav className="ml-auto">
                        <Nav.Link
                            as={Link}
                            to="/"
                            className={`
                                ${darkMode ? 'text-white' : 'text-black'}
                                transition-all duration-300 mx-2 !text-sm
                            `}
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to="/projects_blogs"
                            className={`
                                ${darkMode ? 'text-white' : 'text-black'}
                                transition-all duration-300 mx-2 !text-sm
                            `}
                        >
                            Projects & Blogs
                        </Nav.Link>
                        {/* <Nav.Link
                            as={Link}
                            to="/blogs"
                            className={`
                                ${darkMode ? 'text-white' : 'text-black'}
                                transition-all duration-300 mx-2 !text-sm
                            `}
                        >
                            Blogs
                        </Nav.Link> */}

                        {/* Dark mode toggle placed after the nav links */}
                        <button
                            onClick={handleToggle}
                            className="
                                p-2 
                                bg-gray-300 
                                text-gray-800 
                                dark:text-gray-200 
                                dark:bg-neutral-800 
                                rounded-lg flex 
                                items-center 
                                justify-center 
                                transition-all 
                                duration-300
                                ml-4
                            "
                        >
                            <span
                                className={`
                                    transition-opacity 
                                    duration-300 
                                    ${iconFade ? 'opacity-0' : 'opacity-100'}
                                `}
                            >
                                {darkMode ? <FaSun size={20} /> : <FaMoon size={20} />}
                            </span>
                        </button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default CustomNavbar;
