import React from 'react';

const AnimatedLink = ({ 
    href, 
    txt, 
    icon, 
    textColor="text-rose-500", 
    afterColor="hover:text-rose-600", 
    borderColor="after:bg-rose-600",
    darkTextColor="dark:text-rose-300",
    darkAfterColor="dark:hover:text-rose-400",
    darkBorderColor="dark:after:bg-rose-400",
}) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`
        ${textColor} ${darkTextColor} 
        relative
        overflow-hidden
        after:content-['']
        after:absolute
        after:left-0
        after:bottom-0
        ${icon ? 'after:w-20' : 'after:w-full'}
        after:h-[2px]
        ${borderColor} ${darkBorderColor} 
        after:opacity-0
        after:transition-opacity
        after:duration-300
        hover:after:opacity-100
        ${afterColor} ${darkAfterColor} 
        ${icon ? 'flex items-center' : ''}
      `}
    >
      {icon}{txt}
    </a>
  );
};

export {AnimatedLink};
