import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { ThemeContext } from '../ThemeContext';
import './projects.css';
import { Helmet } from 'react-helmet';

function Projects() {
    const { darkMode } = React.useContext(ThemeContext);

    const [repos, setRepos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchRepos = async () => {
            try {
                const response = await axios.get(
                    'https://api.github.com/users/keynekassapa13/repos?sort=pushed'
                );
                setRepos(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        const fetchRSS = async () => {
            try {
                const response = await axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@keynekassapa13');
                console.log(response.data);
                setBlogs(response.data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchRepos();
        fetchRSS();
        
    }, []);

    if (loading) return <div className="text-center text-gray-500">Loading...</div>;
    if (error) return <div className="text-center text-red-500">Error: {error}</div>;

    return (
        <div className="
        projects max-w-4xl mx-auto p-4 
        bg-gray-100 dark:bg-neutral-800 
        text-gray-900 dark:text-gray-100">
            <Helmet>
                <title>Keyne Oei - Projects & Blogs</title>
            </Helmet>
            <div className='row'>
                <div className='col-md-6'>
                    <h1 className="text-lg mb-4 text-left merriweather-bold">Projects/GitHub</h1>
                    <ul className="space-y-2 open-sans text-sm border-1 shadow overflow-y-scroll">
                        {repos && repos.map((repo) => (
                            <li key={repo.id} 
                            className="
                            border-solid 
                            px-4 py-1 ">
                                
                                <a
                                    href={repo.html_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="
                                    text-rose-500 dark:text-rose-300 
                                    hover:text-white
                                    hover:bg-rose-800
                                    block
                                    "
                                >
                                    <div className='text-base'>{repo.name}</div>
                                </a>
                                <div className='merriweather-light-italic text-xs'>{repo.description}</div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='col-md-6'>
                    <h1 className="text-lg mb-4 text-left merriweather-bold">Blogs</h1>
                    <ul className="space-y-2 open-sans text-sm border-1 shadow overflow-y-scroll">
                        {blogs && blogs.items && blogs.items.map((blog) => (
                            <li key={blog.id} 
                            className="
                            border-solid 
                            px-4 py-1 ">
                                <a
                                    href={blog.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="
                                    text-rose-500 dark:text-rose-300 
                                    hover:text-white
                                    hover:bg-rose-800
                                    block
                                    "
                                >
                                    <div className='text-base'>{blog.title}</div>
                                </a>
                                <div className='merriweather-light-italic text-xs'>{blog.categories.join(', ')}</div>
                            
                            </li>
                        ))}
                    </ul>
                </div>    
            </div> 
            
        </div>
    )
}

export default Projects;