import React from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';
import './home.css';
import { FaGithub, FaLinkedin, FaGoogle } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { GrDocument } from "react-icons/gr";
import { AnimatedLink } from '../utils/utils';
import { ThemeContext } from '../ThemeContext';

function AdditionalInfo() {
    const education = [
        {
            degree: "Master of Visual Computing",
            institution: "Universität des Saarlandes",
            year: "2019 - 2021"
        },
        {
            degree: "Bachelor of Computer Science",
            institution: "University of Queensland & University of Indonesia",
            year: "2015 - 2019"
        }
    ];

    const achievementsData = [
        {
            title: "2nd Place of Rendering Competition",
            date: '2020',
            description: "Saarland Informatic Campus in Computer Graphic Course (Prof. Dr.-Ing. Philipp Slusallek)"
        },
        {
            title: "EAIT Scholar",
            date: '2018',
            description: "University of Queensland, "
        },
        {
            title: "Highest Grade Point Average (GPA) Student",
            date: '2019',
            description: "International Computer Science Degree, University of Indonesia"
        },
        {
            title: "Dean’s Commendation for Academic Excellence",
            date: '2018',
            description: "University of Queensland"
        }
    ];

    return (
        <div className='container py-3'>
            <div className='row py-3'>
                <div className='col-md-3 merriweather-bold fs-4'>
                    Education
                </div>
                <div className='col-md-9'>
                {education.map((edu, index) => (
                    <React.Fragment key={index}>
                        <div className='py-1'>
                            <div className='quicksand-bold'>{edu.degree}</div>
                            <div>{edu.institution}</div>
                        </div>
                    </React.Fragment>
                ))}
                </div>
            </div>

            <div className='row'>
                <div className='col-md-3 merriweather-bold fs-4'>
                    Achievements
                </div>
                <div className='col-md-9'>
                    {achievementsData.map((achievement, index) => (
                        <React.Fragment key={index}>
                            <div className='py-1'>
                                <div className='quicksand-bold'>{achievement.title}</div>
                                <div>{achievement.description}</div>
                                <div className='date-and-location'>
                                    <span>{achievement.date}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}

function Home() {
    const { darkMode } = React.useContext(ThemeContext);
    return (
        <div className={`
            App open-sans
            !text-sm/6
        `}>
            <Helmet>
                <title>Keyne Oei - Home</title>
            </Helmet>
            <div className='container py-5'>
                <div className='row'>
                    <div className='col-lg-4 col-md-5 d-flex align-items-center justify-content-center'>
                        <img src='self2.jpg' className='img-fluid img-profile w-[300px] md:w-100' alt='Keyne Oei'/>
                    </div>
                    <div className='col-lg-8 col-md-7'>
                        {/* <AdditionalInfo /> */}
                        <div className='container px-3 py-1'>
                            <div className='row text-sm'>
                                <div className='col-md-2'>
                                    <AnimatedLink href='public/resume.pdf' txt='Resume' icon={<GrDocument className='mr-1'/>} />
                                </div>
                                <div className='col-md-2'>
                                    <AnimatedLink href="https://github.com/keynekassapa13" txt="Github" icon={<FaGithub className='mr-1'/>} />
                                </div>
                                <div className='col-md-2'>
                                    <AnimatedLink href="https://www.linkedin.com/in/keynekassapa/" txt="LinkedIn" icon={<FaLinkedin className='mr-1'/>} />
                                </div>
                                <div className='col-md-2'>
                                    <AnimatedLink href="mailto:keynekassapa13@gmail.com" txt="Email" icon={<MdEmail className='mr-1'/>} />
                                </div>
                                <div className='col-md-2'>
                                    <AnimatedLink href="https://scholar.google.com/citations?user=MDMNMsoAAAAJ&hl=en" txt="Scholar" icon={<FaGoogle className='mr-1'/>} />
                                </div>
                            </div>
                        </div>
                        {/* Description */}
                        <div className='p-3 pb-md-4 mx-auto !text-pretty'>
                            {/* <p className='py-2 merriweather-bold fs-4'>Hi! My name is Keyne Oei.</p> */}
                            <p>✨ Recent News ✨: I made <AnimatedLink href="http://vidtex.ai" txt="Vidtex.ai"></AnimatedLink> !!</p>
                            <p className='py-2'>
                            I am a Machine Learning/Artificial Intelligence (ML/AI) engineer currently 
                            working as a Research Assistant at the <AnimatedLink href="https://www.uni-saarland.de/en/home.html" txt="Universitat des Saarlandes"/>, Saarbrucken, Germany.
                            My research focuses on video understanding and the alignment of videos, particularly in relation to human-computer interaction. 
                            I am supervised by <AnimatedLink href="http://annafeit.de" txt="Prof. Anna Maria Feit"/>, <AnimatedLink href="https://cvmp.cs.uni-saarland.de/#eddy-ilg" txt="Prof. Eddy Ilg"/>, and <AnimatedLink href="https://scholar.google.dk/citations?user=ANS7JzkAAAAJ&hl=en" txt="João Belo"/>.
                            Previously, I worked as a Research Intern at the <AnimatedLink href="https://uq.edu.au/" txt="University of Queensland "/> with <AnimatedLink href="https://researchers.uq.edu.au/researcher/22072" txt="Dr. Jessica Korte "/> 
                            on real-time machine learning for Auslan recognition, aimed at promoting social robotics interaction.
                            Before that, I was a software engineer at <AnimatedLink href='https://www.jacques.com.au/' txt="Jacques Technologies" /> in Brisbane, Australia, 
                            where I developed software for the Intercom Report Server.
                            </p>
                            <p className='py-2'>
                            My ultimate goal is to contribute to the broader advancements in machine learning. 
                            I am always eager to learn and improve myself, constantly seeking new opportunities to grow.
                            Please feel free to reach out to me if you have any questions or would like to collaborate.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <footer>
            </footer>
        </div>
    );
}

export default Home;